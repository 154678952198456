import React, { useEffect, useRef } from 'react';

const apiKey = process.env.REACT_APP_DICE_KEY;
const partnerId = process.env.REACT_APP_DICE_PARTNER_ID;

const EventList = () => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (!iframe) return;

    const customStyles = `
      @import url('https://fonts.googleapis.com/css2?family=Afacad:ital,wght@0,400..700;1,400..700&display=swap');
      body {
        font-family: 'Afacad';
        color: #fff;
        overflow: hidden;
      }

      a > img {
        border: solid 3px;
        border-image-outset: 15px;
        border-color: #B39055;      
      }

      div, time {
        color: #D2CAAF;      
      }

      .sc-lbOyJj.bDiDet.dice_event-title {
        color: #D2CAAF;
      }

      /* Add more custom styles as needed */
    `;
    
    const adjustIframeHeight = () => {
      if (iframe.contentWindow.document.body) {
        const newHeight = iframe.contentWindow.document.body.scrollHeight;
        iframe.style.height = `${newHeight}px`;
      }
    }

    const scriptContent = `
      (function() {
        const style = document.createElement('style');
        style.type = 'text/css';
        style.appendChild(document.createTextNode(\`${customStyles}\`));
        document.head.appendChild(style);

        const script = document.createElement('script');
        script.src = 'https://widgets.dice.fm/dice-event-list-widget.js';
        script.type = 'text/javascript';
        script.async = true;
        script.onload = function() {
          window.DiceEventListWidget.create({
            information: 'full',
            eventTitle: 'event',
            showImages: true,
            showAudio: true,
            showNewBadge: false,
            hidePostponed: false,
            hideCancelled: true,
            layout: 'gallery',
            roundButtons: false,
            theme: 'dark',
            fontFamily: 'Afacad',
            partnerId: '${partnerId}',
            apiKey: '${apiKey}',
            version: 2,
            showPrice: true,
            highlightColour: '#B39055',
            promoters: ['Music Box of Rockford LLC']
          });
          
          // Adjust iframe height after widget loads
          window.addEventListener('load', function() {
            window.parent.adjustIframeHeight();
          });

          // Listen for changes in widget content
          new ResizeObserver(() => window.parent.adjustIframeHeight())
            .observe(document.getElementById('dice-event-list-widget'));
        };
        document.body.appendChild(script);
      })();
    `;

    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(`
      <!DOCTYPE html>
      <html lang="en">
      <head><title>DICE Widget</title></head>
      <body>
        <div id="dice-event-list-widget"></div>
        <script type="text/javascript">${scriptContent}</script>
      </body>
      </html>
    `);
    iframe.contentWindow.document.close();

        // Expose function to parent window to adjust iframe height
        window.adjustIframeHeight = adjustIframeHeight;

  }, []);

  return (
    <iframe
        id="event-list-iframe"
        ref={iframeRef}
        title="DICE Event List"
    />
  );
};

export default React.memo(EventList);

